import Layout from "../components/Layout";
import SEO from "components/seo";
import { useRouter } from "next/router";
import PageNotFoundSVG from "public/svgs/404.svg";
import Head from "next/head";
import EPButton from "components/UI/EPButton";
export default function PageNotFound() {
  const router = useRouter();
  return (
    <>
      <SEO
        title="Page Not Found | Epicplay.in"
        url={router.pathname}
        useTitleTemplate={false}
      />
      <Head>
        <meta name="robots" content="noindex, nofollow" />
        <meta name="googlebot" content="noindex, nofollow" />
      </Head>
      <div className="container404">
        <PageNotFoundSVG alt="page not found" width="auto" height="50vh" />
        <EPButton href="/" variant="primary">
          Visit Homepage
        </EPButton>
      </div>
    </>
  );
}
// more optimized
PageNotFound.getLayout = function getLayout(page) {
  return <Layout>{page}</Layout>;
};
